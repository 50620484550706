import { CashRegisterStatus } from "./cash-register";
import { CashTransactionType } from "./cash-transaction";
import { PromotionStatus } from "./promotion";

export class PagingQuery {
    offset!: number;
    limit!: number;
}

export class BillOfMaterialQuery extends PagingQuery {
    owners?: string[] = [];
    editors?: string[] = [];
    is_default?: boolean;
    active?: boolean;
    products?: string[] = [];
    created_date_start?: Date;
    created_date_end?: Date;
}

export class ProductionOrderQuery extends PagingQuery {
    owners?: string[] = [];
    editors?: string[] = [];
    statuses?: number[] = [];
    plans?: string[] = [];
    active?: boolean;
    products?: string[] = [];
    created_date?: Date;
    due_date?: Date;
    start_date?: Date;
    public static restFilter(query: ProductionOrderQuery) {
        query.offset = 0;
        query.owners = [];
        query.editors = [];
        query.statuses = [];
        query.plans = [];
        query.active = undefined;
        query.products = [];
        query.created_date = undefined;
        query.due_date = undefined;
        query.start_date = undefined;
    }
}

export class ProductionPlanningQuery extends PagingQuery {
    documentStatuses?: string[] = [];
    documentCodes?: string[] = [];
    stockItemIds?: number[] = [];
    customerIds?: number[] = [];
    salesPersonIds?: number[] = [];
    dueDate?: Date;
    documentDateFrom?: Date;
    documentDateTo?: Date;
    public static restFilter(query: ProductionPlanningQuery) {
        query.offset = 0;
        query.documentStatuses = [];
        query.documentCodes = [];
        query.stockItemIds = [];
        query.customerIds = [];
        query.salesPersonIds = [];
        query.dueDate = undefined;
        query.documentDateFrom = undefined;
        query.documentDateTo = undefined;
    }
}

export class AuditLogQuery extends PagingQuery {
    entity_id!: string;
    user_id?: string;
    event_type?: string;
    entity_name?: string;
}

export class PromotionQuery extends PagingQuery {
    name?: string;
    startDateFrom?: Date;
    startDateTo?: Date;
    endDateFrom?: Date;
    endDateTo?: Date;
    status?: PromotionStatus;
}

export class CashRegisterQuery extends PagingQuery {
    code?: string;
    name?: string;
    deviceId?: string;
    activatedUser?: string;
    status?: CashRegisterStatus;
    cashRegisters?: string[];
    openedByUsers?: string[];
    cardLayoutId?: string;
}

export class CashTransactionQuery extends PagingQuery {
    recordedDateStart?: Date;
    recordedDateEnd?: Date;
    recordedByUsers?: string[];
    transactionType?: CashTransactionType;
}

export class CashRegistersClosuresQuery extends PagingQuery {
    cashRegisters?: string[];
    openingDateFrom?: Date;
    openingDateTo?: Date;
    openedByUsers?: string[];
    closingDateFrom?: Date;
    closingDateTo?: Date;
    closedByUsers?: string[];
    businessDay?: Date;
}

export class SupplierQuery extends PagingQuery {
    email?: string;
    name?: string;
    mobile?: string;
}



export class ProductQuery extends PagingQuery {
    name?: string;
    description?: string;
    sku?: string;
    partNumber?: string;
}

export class LocationQuery extends PagingQuery {
    name?: string;
    mobile?: string;
}

export class CustomerQuery extends PagingQuery {
    name?: string;
    mobile?: string;
    email?: string;
    phone?: string;
    syncStatus?: string;
}

export class IntegrationLogsQuery extends PagingQuery {
    description?: string;
    eventDateFrom?: Date;
    eventDateTo?: Date;
    entityName?: string;
    eventType?: string;
    error?: boolean;
}

export class SalesOrderQuery extends PagingQuery {
    code?: string;
    orderDateStart?: Date;
    orderDateEnd?: Date;
    locations?: string[];
    fulfillmentLocations?: string[];
    customers?: string[];
    cashRegisters?: string[];
    status?: string;
    closureCode?: string;
    disablePagination?: boolean;
}

export class CashRegisterSalesSummaryQuery {
    cashRegisterId?: string;
    closureId?: string;
}

export class PurchaseInvoiceQuery extends PagingQuery {
    suppliers?: string[];
    invoiceDateStart?: Date;
    invoiceDateEnd?: Date;
    dueDateStart?: Date;
    dueDateEnd?: Date;
    paymentStatus?: string
}

export class DebitNoteQuery extends PagingQuery {
    suppliers?: string[];
    invoiceDateStart?: Date;
    invoiceDateEnd?: Date;
    dueDateStart?: Date;
    dueDateEnd?: Date;
    paymentStatus?: string
}
